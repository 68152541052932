import { api } from '_services';
import { userResult } from './user.result';

export const userService = {
	getAll(): Promise<Array<userResult>> {
		return api.call('GET', '/admin/users/').then((json) => {
			return json.data;
		});
	},
	getAllWithDevice(): Promise<Array<userResult>> {
		return api.call('GET', '/admin/users/devices/').then((json) => {
			return json.data;
		});
	},
	getCount() {
		return api.call('GET', '/admin/users/count/');
	},
};
