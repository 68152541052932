import {
	QueryFunctionContext,
	QueryKey,
	useInfiniteQuery,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult,
	useMutation,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { Reponse } from '../../../utils/request';
import { api } from '../../../_services';
import {
	SearchResponse,
	SerieItemAdminDto,
	SeriesDto,
	SerieVariant,
	ValidationState,
} from '../../../types/global';

interface UseSerieProps {
	override: boolean;
}
export const useSerie = (
	id: number,
	options: UseQueryOptions<Reponse<SeriesDto>> = {},
): UseQueryResult<Reponse<SeriesDto>> => {
	return useQuery<Reponse<SeriesDto>>(
		['series', id],
		() => api.call('GET', `/admin/series/${id}/`) as Promise<Reponse<SeriesDto>>,
		{
			...options,
		},
	);
};

interface MutationSerieUpdUser {
	serieId: number;
	userId: number;
}
export const useMutationSerieUpdUser = () => {
	return useMutation(async (submitData: MutationSerieUpdUser) => {
		return api.call('PUT', `/admin/series/${submitData.serieId}/usr/`, {
			userId: submitData.userId,
		}) as Promise<Reponse<boolean>>;
	});
};

interface MutationSerieUpdUserState {
	serieId: number;
	state: ValidationState;
}
export const useMutationSerieUpdUserState = () => {
	return useMutation(async (submitData: MutationSerieUpdUserState) => {
		return api.call('PUT', `/admin/series/${submitData.serieId}/state/`, {
			state: submitData.state,
		}) as Promise<Reponse<boolean>>;
	});
};

export const useSerieItems = (
	id: number,
	options: UseQueryOptions<Reponse<SerieItemAdminDto[]>> = {},
): UseQueryResult<Reponse<SerieItemAdminDto[]>> => {
	return useQuery<Reponse<SerieItemAdminDto[]>>(
		['series', 'items', id],
		() =>
			api.call('GET', `/admin/series/${id}/items/`) as Promise<Reponse<SerieItemAdminDto[]>>,
		{
			...options,
		},
	);
};

export const useSerieVariants = (
	id: number,
	options: UseQueryOptions<Reponse<SerieVariant[]>> = {},
): UseQueryResult<Reponse<SerieVariant[]>> => {
	return useQuery<Reponse<SerieVariant[]>>(
		['series', 'variantes', id],
		() => api.call('GET', `/admin/series/${id}/variants/`) as Promise<Reponse<SerieVariant[]>>,
		{
			...options,
		},
	);
};

interface MutationSerieItemsUpdUser {
	serieId: number;
	itemId: number;
	userId: number;
	newUserId: number;
}
export const useMutationSerieItemsUpdUser = () => {
	return useMutation(async (submitData: MutationSerieItemsUpdUser) => {
		return api.call(
			'PUT',
			`/admin/series/${submitData.serieId}/items/${submitData.itemId}/user/${submitData.userId}/upd/`,
			{ userId: submitData.newUserId },
		) as Promise<Reponse<boolean>>;
	});
};

interface MutationSerieItemsUpdUserState {
	serieId: number;
	itemId: number;
	userId: number;
	state: ValidationState;
}
export const useMutationSerieItemsUpdUserState = () => {
	return useMutation(async (submitData: MutationSerieItemsUpdUserState) => {
		return api.call(
			'PUT',
			`/admin/series/${submitData.serieId}/items/${submitData.itemId}/user/${submitData.userId}/state/`,
			{ state: submitData.state },
		) as Promise<Reponse<boolean>>;
	});
};

export const useSeries = (
	props: UseSerieProps,
	options: UseInfiniteQueryOptions<Reponse<SearchResponse<SeriesDto>>> = {},
): UseInfiniteQueryResult<Reponse<SearchResponse<SeriesDto>>> => {
	return useInfiniteQuery<Reponse<SearchResponse<SeriesDto>>>(
		['series', props],
		({ pageParam = 0 }: QueryFunctionContext<QueryKey, number>) =>
			api.call(
				'GET',
				`/admin/series/?page=${pageParam / 30}&override=${props.override ? 1 : 0}`,
			) as Promise<Reponse<SearchResponse<SeriesDto>>>,
		{
			...options,
			getNextPageParam: (lastPage) => {
				return lastPage.data.nextOffset;
			},
		},
	);
};

export const useSearchSerieInfinite = (
	text: string,
	options: UseInfiniteQueryOptions<Reponse<SearchResponse<SeriesDto>>> = {},
): UseInfiniteQueryResult<Reponse<SearchResponse<SeriesDto>>> => {
	return useInfiniteQuery<Reponse<SearchResponse<SeriesDto>>>(
		['series', 'search', text],
		() =>
			api.call('GET', `/series/search/?keyword=${text}&limit=5`) as Promise<
				Reponse<SearchResponse<SeriesDto>>
			>,
		{
			enabled: text !== undefined && text.length > 2,
			...options,
			getNextPageParam: (lastPage) => {
				return undefined;
			},
		},
	);
};

export const useSearchSerie = (
	text: string,
	options: UseQueryOptions<Reponse<SearchResponse<SeriesDto>>> = {},
): UseQueryResult<Reponse<SearchResponse<SeriesDto>>> => {
	return useQuery<Reponse<SearchResponse<SeriesDto>>>(
		['series', 'search', text],
		() =>
			api.call('GET', `/series/search/?keyword=${text}&limit=5`) as Promise<
				Reponse<SearchResponse<SeriesDto>>
			>,
		{
			enabled: text.length > 2,
			...options,
		},
	);
};
