import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Wrapper from 'components/Wrapper';
import React, { useEffect, useState } from 'react';
import { userService } from '_services';
import { userResult } from '_services/user.result';

interface UsersListData {
	loaded: boolean;
	users: Array<userResult>;
}
const UsersList = (props: any) => {
	const [data, setData] = useState({
		loaded: false,
		users: [],
	} as UsersListData);

	useEffect(() => {
		if (!data.loaded) {
			userService.getAll().then((resp) => {
				setData({ ...data, loaded: true, users: resp });
			});
		}
	}, [data]);

	return (
		<>
			<Wrapper>
				<TableContainer className='mb-4'>
					<Table aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>id</TableCell>
								<TableCell>name</TableCell>
								<TableCell>Date</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.users.map((usr) => {
								return (
									<TableRow key={usr.id} hover>
										<TableCell>{usr.id}</TableCell>
										<TableCell>
											{usr.name}
											<br />
											{usr.email}
										</TableCell>
										<TableCell>
											{new Date(usr.created).toLocaleDateString()}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Wrapper>
		</>
	);
};

export default UsersList;
