import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Reponse } from '../../../utils/request';
import { api } from '../../../_services';
import { SearchResponse } from '../../../types/global';
import { Author } from './use-authors';

export const useAuthorsSearch = (
	keyword: string,
	options: UseQueryOptions<Reponse<SearchResponse<Author>>> = {},
): UseQueryResult<Reponse<SearchResponse<Author>>> => {
	return useQuery<Reponse<SearchResponse<Author>>>(
		['authors', keyword],
		() =>
			api.call('GET', `/author/search/?keyword=${keyword}`) as Promise<
				Reponse<SearchResponse<Author>>
			>,
		{
			...options,
		},
	);
};

export const useAdminAuthorsSearch = (
	keyword: string,
	options: UseQueryOptions<Reponse<SearchResponse<Author>>> = {},
): UseQueryResult<Reponse<SearchResponse<Author>>> => {
	return useQuery<Reponse<SearchResponse<Author>>>(
		['authors', 'admin', keyword],
		() =>
			api.call('GET', `/admin/authors/search/?keyword=${keyword}`) as Promise<
				Reponse<SearchResponse<Author>>
			>,
		{
			...options,
		},
	);
};
