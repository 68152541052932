import Button from '@mui/material/Button';
import React from 'react';
import { searchService } from '_services';
import Alert from '@mui/material/Alert';

class Images extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
		};
	}
	clean() {
		searchService.cleanImg().then((response) => {
			this.setState({ msg: 'clean des images en cours, async pas de retour' });
		});
	}
	resize() {
		searchService.resizeImg().then((response) => {
			this.setState({ msg: 'resize des images en cours, async pas de retour' });
		});
	}
	render() {
		const { msg } = this.state;
		return (
			<>
				<div>
					<Button variant='contained' disableElevation onClick={() => this.clean()}>
						Clean les images
					</Button>
					<Button variant='contained' disableElevation onClick={() => this.resize()}>
						Resize toutes les images
					</Button>
					<Alert severity='info'>{msg}</Alert>
				</div>
			</>
		);
	}
}

export default Images;
