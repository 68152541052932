import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { api, itemResult, itemService } from '_services';
import { ItemDto } from '../../types/global';
import { Reponse } from '../../utils/request';

type UseItemProps = {
	brewery: number;
	type: number;
	overrides: string;
	search: string;
	history: any;
};
export const useItems = (
	props: UseItemProps,
	options: UseQueryOptions<Reponse<itemResult[]>> = {},
): UseQueryResult<Reponse<itemResult[]>> => {
	return useQuery<Reponse<itemResult[]>>(
		['item', 'all', JSON.stringify(props)],
		() => itemService.get(props.type, props.brewery, props.overrides, props.search),
		{
			...options,
			staleTime: 60 * 1000,
		},
	);
};
