import React from 'react';
import Wrapper from '../../../components/Wrapper';
import { api } from '../../../_services';
import PageTitle from 'layout-components/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import { useAuthorRole } from './use-authors-roles';
import AuthorRoleSearchModal from './AuthorRoleSearchModal';

const AuthorRolePage = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { data: authorRole, isLoading } = useAuthorRole(Number(id) ?? 0);

	if (!authorRole?.data || isLoading) {
		return <Loader />;
	}

	return (
		<>
			<PageTitle titleHeading={authorRole.data.name} titleDescription='' />

			<Wrapper sectionHeading='Detail'>
				<FormControl fullWidth>
					<TextField
						label='Name'
						type={'text'}
						onBlur={(event) =>
							void api.call(
								'PUT',
								`/admin/authors/roles/${authorRole?.data.id}/name/`,
								event.target.value,
							)
						}
						defaultValue={authorRole?.data.name}
					/>
				</FormControl>
			</Wrapper>
			<Wrapper sectionHeading='Variantes'>
				<ul>{authorRole?.data?.variants?.map((value) => <li key={value}>{value}</li>)}</ul>
			</Wrapper>
			<Wrapper>
				<AuthorRoleSearchModal
					authorRole={authorRole.data}
					buttonTitle={'Fusionner'}
					onAdd={(authorRoleId) => {
						{
							void api
								.call(
									'PUT',
									`/admin/authors/roles/${authorRole.data.id}/fusion/${authorRoleId}/`,
								)
								.then(() => navigate(`/book/authorsRoles/${authorRoleId}`));
						}
					}}
				/>
			</Wrapper>
		</>
	);
};

export default AuthorRolePage;
