import React from 'react';
import { api, itemService } from '_services';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonDialog from 'components/Widgets/ButtonDialog';

import { getListImg } from '_helpers';
import Titles from 'pages/items/Titles';
import Users from 'pages/items/Users';

import Wrapper from 'components/Wrapper';
import PageTitle from '../../layout-components/PageTitle';
import EditionEdit from '../book/editions/EditionEdit';
import SerieList from '../book/series/SerieList';
import { useItem, useItemSerie } from './use-item';
import Loader from '../../components/Loader';
import { Button, Divider, Typography } from '@mui/material';
import SourceList from '../book/sources/SourceList';
import ItemFusion from 'pages/items/ItemFusion';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailLanguage } from './DetailLanguage';
import { DetailAuthors } from './DetailAuthors';
import { DetailImages } from './DetailImages';
import { DetailImagesUsers } from './DetailImagesUsers';

const Detail = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { data: item, isLoading, refetch } = useItem(Number(id));
	const { data: series } = useItemSerie(Number(id));

	function deleteItem() {
		void itemService.delete(Number(id)).then(() => {
			navigate('-1');
		});
	}

	function deleteImage() {
		void itemService.deleteMainImage(Number(id)).then(async () => {
			await refetch();
		});
	}

	if (isLoading || !item) {
		return <Loader />;
	} else {
		return (
			<>
				<PageTitle titleHeading={item.data.title} titleDescription="Edition d'un objet" />
				<div className='main-img'>
					{item.data.images?.map((img) => {
						return (
							<>
								<img src={getListImg(img.path)} />
								<br />
								<ButtonDialog
									onValid={() => deleteImage()}
									text='Supprimer'
									color='error'
									startIcon={<DeleteIcon />}
									confirmBtnCancel='Annuler'
									confirmBtnValid='Supprimer'
									confirmTitle='Souhaitez vous supprimer cette image ?'
									confirmDesc="L'action ne sera pas annulable!"
								/>
							</>
						);
					})}
				</div>
				<Wrapper sectionHeading='Titres'>
					<Titles id={item.data.id} currentTitle={item.data.title} onTitleUpd={refetch} />
				</Wrapper>
				<Wrapper sectionHeading='Utilisateurs'>
					<Users id={item.data.id} />
				</Wrapper>
				<Wrapper sectionHeading='Images secondaires'>
					<DetailImages itmId={item.data.id} onImagesUpd={refetch} />
				</Wrapper>
				<Wrapper sectionHeading='Images utilisateurs'>
					<DetailImagesUsers
						itmId={item.data.id}
						mainImages={item.data.images ?? []}
						onImagesUpd={refetch}
					/>
				</Wrapper>
				<Wrapper sectionHeading='Détail'>
					<Typography variant={'body2'}>
						Poids : {item.data.weight}
						<br />
						Prix : {item.data.priceNew}€
						<br />
						Description : {item.data.desc}
						<br />
						Computed : {JSON.stringify(item.data.computed)}
					</Typography>
				</Wrapper>
				<Wrapper sectionHeading='Séries'>
					{series &&
						series.data
							?.filter((value) => value.serie !== undefined)
							.map((s) => {
								const componentSerie = (
									<SerieList
										serie={s.serie!}
										onClick={() => navigate(`/book/series/${s.serie?.id}`)}
									/>
								);

								if (s.user) {
									return (
										<>
											<Typography variant={'caption'}>
												Utilisateur {s.user.name} :
											</Typography>
											{componentSerie}
										</>
									);
								}

								return componentSerie;
							})}
				</Wrapper>
				<DetailAuthors media={item.data} onChange={refetch} />
				<DetailLanguage media={item.data} onChange={refetch} />
				<Wrapper sectionHeading='Codes barres'>
					<EditionEdit itemId={item.data.id} />
				</Wrapper>
				<Wrapper sectionHeading='Sources'>
					{item.data.source?.map((source) => {
						return (
							<SourceList
								key={source.id}
								itemId={item.data.id}
								source={source}
								onClick={() => {}}
								refetch={refetch}
							/>
						);
					})}

					<Divider sx={{ mb: 1 }} />
					<Button
						size='small'
						variant='contained'
						disableElevation
						color='primary'
						onClick={() => {
							void api
								.call('GET', `/admin/item/${item.data.id}/refetch/73/`)
								.then(refetch);
						}}>
						Check Bdphile
					</Button>
				</Wrapper>
				<ButtonDialog
					onValid={() => deleteItem()}
					text='Supprimer'
					color='error'
					startIcon={<DeleteIcon />}
					confirmBtnCancel='Annuler'
					confirmBtnValid='Supprimer'
					confirmTitle='Souhaitez vous supprimer cette objet ?'
					confirmDesc="L'action ne sera pas annulable!"
				/>
				&nbsp;
				<ItemFusion
					media={item.data}
					onFusion={(newid) => {
						navigate(`/items/detail/${newid}`);
					}}
				/>
			</>
		);
	}
};

export default Detail;
