import { TextField, Select, MenuItem } from '@mui/material';
import ButtonPrimary from 'components/Widgets/ButtonPrimary';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { notifyService, userService } from '_services';
import { userResult } from '_services/user.result';

interface UsersListData {
	loaded: boolean;
	users: Array<userResult>;
}

const validationSchema = Yup.object({
	title: Yup.string().required('title is required'),
	desc: Yup.string().required('desc is required'),
});

const EditForm = (props) => {
	const [data, setData] = useState({
		loaded: false,
		users: [],
	} as UsersListData);

	useEffect(() => {
		if (!data.loaded) {
			void userService.getAllWithDevice().then((resp) => {
				setData({ ...data, loaded: true, users: resp });
			});
		}
	}, [data]);

	const formik = useFormik({
		initialValues: {
			send: false,
			link: props.link ? props.link : '',
			title: props.title ? props.title : '',
			desc: props.desc ? props.desc : '',
			usrId: props.usrId ? props.usrId : 0,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			void notifyService
				.push(values.title, values.desc, values.link, values.usrId)
				.then((res) => {
					formik.setFieldValue('send', true);
				});
		},
	});

	return (
		<>
			{formik.values.send && 'Message envoyé'}
			{!formik.values.send && (
				<form onSubmit={formik.handleSubmit}>
					<Select
						fullWidth
						className='m-2'
						id='usrId'
						value={formik.values.usrId}
						label='Utilisateur'
						onChange={(evt) => formik.setFieldValue('usrId', evt.target.value)}
						error={formik.touched.usrId && Boolean(formik.errors.usrId)}>
						<MenuItem value={0}>Tous</MenuItem>

						{data.users.map((usr) => {
							return (
								<MenuItem value={usr.id}>
									{usr.id} - {usr.name}
								</MenuItem>
							);
						})}
					</Select>

					<TextField
						fullWidth
						className='m-2'
						id='link'
						name='link'
						label='Link'
						value={formik.values.link}
						onChange={formik.handleChange}
						error={formik.touched.link && Boolean(formik.errors.link)}
						helperText={formik.touched.link && formik.errors.link}
					/>

					<TextField
						fullWidth
						className='m-2'
						id='title'
						name='title'
						label='Titre'
						value={formik.values.title}
						onChange={formik.handleChange}
						error={formik.touched.title && Boolean(formik.errors.title)}
						helperText={formik.touched.title && formik.errors.title}
					/>

					<TextField
						fullWidth
						className='m-2'
						id='desc'
						name='desc'
						label='Desc'
						value={formik.values.desc}
						onChange={formik.handleChange}
						error={formik.touched.desc && Boolean(formik.errors.desc)}
						helperText={formik.touched.desc && formik.errors.desc}
					/>

					<ButtonPrimary onClick={() => formik.handleSubmit()} text='Valider' />
				</form>
			)}
		</>
	);
};

export default EditForm;
